// Landing Page Data
export const landingPageData = {
  coming_soon: "!Muy Pronto¡",
  heading: "Nuestro sitio está en camino",
  notify: {
    text: "Recibe una notificación al estar listo.",
    button: "Notificarme",
    placeholder: "Ingresa tu Email",
  },
};

// Social Media List
export const socialMediaData = [
  {
    icon: "fab fa-twitter",
    link: "http://www.twitter.com/",
    name: "Twitter",
    class: "social-icons-twitter",
  },
  {
    icon: "fab fa-facebook",
    link: "http://www.facebook.com/",
    name: "Facebook",
    class: "social-icons-facebook",
  },
  {
    icon: "fab fa-instagram",
    link: "http://www.instagram.com/",
    name: "Instagram",
    class: "social-icons-instagram",
  },
];

// About Us Data
export const aboutData = {
  desc: "We're a digital agency based in USA. We combine our passion for design focused in people with advanced development technologies. Thousands of clients have procured exceptional results while working with our dedicated team.",
  cards: [
    {
      icon: "far fa-thumbs-up",
      title: "Why Choose Us",
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text.",
    },
    {
      icon: "far fa-paper-plane",
      title: "Our Mission",
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text.",
    },
    {
      icon: "far fa-eye",
      title: "Our Vision",
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text.",
    },
  ],
  projects_title: "Some of our most recent projects.",
  projects: [
    {
      title: "Project Title",
      category: "Category",
      img: "images/intro-bg-2.jpg",
    },
    {
      title: "Project Title",
      category: "Category",
      img: "images/intro-bg-3.jpg",
    },
    {
      title: "Project Title",
      category: "Category",
      img: "images/intro-bg-4.jpg",
    },
    {
      title: "Project Title",
      category: "Category",
      img: "images/intro-bg-5.jpg",
    },
    {
      title: "Project Title",
      category: "Category",
      img: "images/intro-bg-6.jpg",
    },
    {
      title: "Project Title",
      category: "Category",
      img: "images/intro-bg-7.jpg",
    },
  ],
  get_in_touch: "Get in touch with us",
};

// Contact Us data
export const contactData = {
  desc: "Próximamente lanzamos nuestro nuevo sitio. Para atención al cliente y consultas, póngase en contacto con nosotros.",
  support_note: "For Customer Support, Send us a note.",
  cards: [
    {
      icon: "fas fa-map-marker-alt",
      title: "VISIT US",
      lines: ["145 Murphy Canyon Rd.", "Suite 100-18, San Diego CA 2028"],
    },
    {
      icon: "fas fa-phone-alt",
      title: "CALL US NOW",
      lines: ["Phone: (+060) 9898980098", "Fax: (+060) 8898880088"],
    },
    {
      icon: "fas fa-envelope",
      title: "INQUIRIES",
      lines: ["info@zooninc.com", "hr@zooninc.com"],
    },
    {
      icon: "fas fa-clock",
      title: "BUSINESS HOURS",
      lines: ["Mon to Fri", "(10 am – 8 pm)"],
    },
  ],

  form: {
    nameLable: "What is Your Name:",
    namePlaceholder: "Introduzca su nombre",

    emailLable: "Your Email Address:",
    emailPlaceholder: "Introduzca su Email",

    messageLable: "How can I Help you?",
    messagePlaceholder: "Ingrese su consulta",

    submitButton: "Enviar Mensaje",
  },
  contact: "+52(229)9203324",
};
